import React from "react";
import styled from "styled-components";

import Header from "./header";

import device from "../styles/media-queries";
import Hero from "./hero";

const StyledIntroduction = styled.header`
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjIwOTYiIGhlaWdodD0iOTgyIiB2aWV3Qm94PSIwIDAgMTIwMDAgNTYyMCI+PHBhdGggZD0iTTE5MjAgNTYxNGMtNTItMi0xNTYtOC0yMzAtMTMtNzQtNi0yMDktMTYtMzAwLTIxLTkxLTYtMjAxLTE1LTI0NS0yMC00NC02LTE0NS0xNy0yMjUtMjUtMjg4LTMwLTg3OS0xMjEtOTA5LTE0MC04LTQtMTEtODE1LTExLTI3MDFWMGgxMjAwMHY1MzI3bC05NyAxN2MtNDEyIDcyLTgwMiAxMjEtMTE5OCAxNTEtMTA0IDgtMjQ0IDE5LTMxMCAyNS00OTIgNDMtMTcwMCA0OC0yNDM1IDEwLTI5NC0xNi02MzktMzgtNzUwLTUwLTU4LTYtMTgxLTE3LTI3NS0yNS0zMTgtMjgtNjk0LTcwLTEwMzUtMTE3LTIwOS0yOC0yNTctMjgtNDA1IDEtNTcwIDExMC0xMjM1IDE5Ny0xNzkwIDIzNS0xMjEgOS0yNzQgMjEtMzQwIDI3LTExMSAxMC0xMjIwIDIwLTE0NDUgMTN6IiBmaWxsPSIjNzc1NGE1Ii8+PHBhdGggZD0iTTE4NjAgNTYxMGMtNTI3LTI1LTEwNDktNzgtMTQ5MC0xNDktMjI4LTM3LTM0OS01OS0zNTktNjYtNi0zLTExLTI0LTExLTQ1di0zOGw2OC0xNmMxMDY1LTI0MCAyODMwLTI4MyA0NTgyLTExMCAzODUgMzggOTk5IDEwOSAxMDA3IDExOCA1IDUtNzYgMjEtMzk3IDc3LTUyNSA5Mi0xMDcyIDE2MC0xNTU1IDE5My0xMjEgOS0yNzQgMjEtMzQwIDI3LTE0MiAxMy0xMjc5IDIwLTE1MDUgOXoiIGZpbGw9IiM0ZWFlZjgiLz48L3N2Zz4=");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 40rem;
  padding-top: 3.5rem;
  position: relative;
  width: 100%;

  @media ${device.laptop} {
    min-height: 90rem;
    padding-top: 5.5rem;
  }
`;

const Introduction = () => {
  return (
    <StyledIntroduction>
      <Header />
      <Hero />
    </StyledIntroduction>
  );
};

export default Introduction;
