import React from "react";
import styled from "styled-components";

import device from "../styles/media-queries";
import colors from "../styles/colors";

const StyledTextDecorator = styled.span`
  background: ${colors.primaryBlue};
  border-radius: 0.7rem;
  display: block;
  height: 1.5rem;
  margin: 1.5rem auto 4.5rem auto;
  width: 7.5rem;

  @media ${device.tablet} {
    margin: ${props =>
      props.centered ? "2.5rem auto 4.5rem" : "2.5rem 0 4.5rem"};
  }

  @media ${device.laptop} {
    margin-left: ${props => (props.centered ? "auto" : "0.75rem")};
  }
`;

const TextDecorator = ({ centered }) => (
  <StyledTextDecorator centered={centered} />
);

export default TextDecorator;
