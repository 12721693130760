import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import MainHeading from "./main-heading";
import TextDecorator from "./text-decorator";

import GearsIcon from "../images/gears-icon.inline.svg";

import device from "../styles/media-queries";

const StyledHero = styled.div`
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  margin: 0 auto;
  padding-top: 9rem;
  width: 100%;

  @media ${device.tablet} {
    max-width: 130rem;
    padding-top: 15rem;
    padding-bottom: 5rem;
  }

  @media ${device.laptop} {
    padding-top: 25rem;
    padding-bottom: 0;
  }
`;

const StyledCTAWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media ${device.tablet} {
    flex-direction: row;
    max-width: 95rem;
    margin: 0 auto;
  }

  @media ${device.laptopL} {
    max-width: inherit;
    margin: 0;
  }
`;

const StyledGearsWrapper = styled.div`
  max-width: 50rem;
  margin: 0 auto;
  position: relative;
  width: 100%;
`;

const StyledGearsIcon = styled(GearsIcon)`
  bottom: ${props => (props.position === "bottom" ? null : 0)};
  display: none;
  height: 15rem;
  left: ${props => (props.position === "bottom" ? null : 0)};
  position: absolute;
  right: ${props => (props.position === "bottom" ? 0 : null)};
  top: ${props => (props.position === "bottom" ? 0 : null)};
  transform: rotate(${props => (props.rotate ? props.rotate : 0)}deg)
    ${props => (props.position === "bottom" ? "scale(0.55)" : "scale(1)")};
  transition: 1s all ease;
  width: 20rem;
  z-index: 1;

  @media ${device.tablet} {
    display: block;
  }

  @media ${device.laptop} {
    height: 20rem;
    width: 20rem;
  }

  @media ${device.laptopL} {
    height: 25rem;
    width: 25rem;
  }
`;

const Hero = () => {
  const [rotate, setRotate] = useState(0);
  const elementRef = useRef();

  useScrollPosition(
    () => {
      const elementDimensions = elementRef.current.getBoundingClientRect();
      const yPos = elementDimensions.top;
      const isVisible = yPos > 0;

      if (isVisible && yPos !== rotate) {
        setRotate(yPos > rotate ? -yPos : yPos);
      }
    },
    [],
    elementRef,
    true,
    150
  );

  return (
    <StyledHero>
      <StyledCTAWrapper>
        <StyledGearsWrapper ref={elementRef}>
          <StyledGearsIcon rotate={rotate} />
          <StyledGearsIcon position="bottom" rotate={rotate} />
        </StyledGearsWrapper>

        <MainHeading
          headingTag="h2"
          isBig
          headingText="Front-end"
          subheadingText="Developer"
        >
          <TextDecorator />
        </MainHeading>
      </StyledCTAWrapper>
    </StyledHero>
  );
};

export default Hero;
