import React from "react";
import Helmet from "react-helmet";

const SEO = () => (
  <Helmet>
    <html lang="en" />
    <meta charSet="utf-8" />
    <title>Przemyslaw Kalisiewicz - Front end developer</title>
    <meta
      name="description"
      content="Przemyslaw Kalisiewicz - Front end developer portfolio. You need a developer or a consultancy? Do not hesitate to contact me by sending a contact form message."
    />
    <meta
      property="og:title"
      content="Przemyslaw Kalisiewicz | Front-end Developer"
    />
    <meta
      property="og:site_name"
      content="Przemyslaw Kalisiewicz - Front-end Developer portfolio"
    />
    <meta property="og:url" content="https://przem.dev" />
    <meta
      property="og:description"
      content="Przemyslaw Kalisiewicz - Front end developer portfolio. You need a developer or a consultancy? Do not hesitate to contact me by sending a contact form message."
    />
    <meta property="og:type" content="profile" />
    <meta property="og:image" content="https://przem.dev/images/og-image.jpg" />
    <link rel="dns-prefetch" href="https://www.google-analytics.com" />
    <link rel="dns-prefetch" href="https://www.google.com" />
  </Helmet>
);

export default SEO;
