import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
        font-family: Montserrat, sans-serif;
    }

    html {
        font-size: 62.5%;
    }

    html, body {
        height: 100%;
    }

    body {
        margin: 0;
        padding: 0;
        width: 100%;
    }

    img {
        max-width: 100%;
    }
`;
