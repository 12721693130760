import React from "react";
import styled, { css } from "styled-components";

import device from "../styles/media-queries";
import colors from "../styles/colors";

const StyledMainHeading = styled.h1`
  color: ${colors.primaryWhite};
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1;
  margin: 0;
  text-align: left;
  z-index: 10;

  @media ${device.tablet} {
    font-size: 2rem;
    text-align: left;
  }

  @media ${device.laptop} {
    font-size: 2.3rem;
  }

  @media ${device.laptopL} {
    font-size: 2.3rem;
  }

  ${props =>
    props.isBig &&
    css`
      font-size: 3rem;
      line-height: 1.25;
      margin-top: 2rem;
      padding: 2rem;
      text-align: center;
      width: 100%;
      z-index: 0;

      @media ${device.tablet} {
        font-size: 4.3rem;
        text-align: left;
      }

      @media ${device.laptop} {
        font-size: 6.3rem;
      }

      @media ${device.laptopL} {
        font-size: 9.3rem;
      }
    `}
`;

const StyledMainSubheading = styled.span`
  display: block;
  font-weight: 700;
  text-transform: uppercase;
`;

const MainHeading = ({
  headingTag = "h1",
  subheadingTag = "span",
  isBig = false,
  children,
  className,
  styleOverride,
  headingText = "Przemyslaw",
  subheadingText = "Kalisiewicz",
}) => (
  <StyledMainHeading
    as={headingTag}
    isBig={isBig}
    style={styleOverride}
    className={className}
  >
    {headingText}{" "}
    <StyledMainSubheading as={subheadingTag} isBig={isBig}>
      {subheadingText}
    </StyledMainSubheading>
    {children && children}
  </StyledMainHeading>
);

export default MainHeading;
