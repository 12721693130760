import React from "react";
import styled from "styled-components";

import Introduction from "./introduction";

import GlobalStyles from "../styles/global-styles";

const StyledLayout = styled.div`
  position: relative;
`;

const Layout = ({ children }) => (
  <StyledLayout id="main">
    <GlobalStyles />
    <Introduction />
    <main>{children}</main>
  </StyledLayout>
);

export default Layout;
