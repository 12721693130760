import React from "react";
import { animated, useSpring, config } from "react-spring";
import styled from "styled-components";

import device from "../styles/media-queries";

const StyledHamburgerButton = styled.button.attrs(({ isMenuActive }) => ({
  "aria-label": "Open menu on mobile button",
  "aria-expanded": isMenuActive,
}))`
  border: none;
  background: transparent;
  height: 4.4rem;
  margin: 0;
  padding: 0;
  width: 4.4rem;
  transition: 1s all ease;
  z-index: 20;

  @media ${device.tablet} {
    display: none;
  }
`;

const StyledHamburgerIcon = styled(animated.svg)`
  height: 100%;
  pointer-events: none;
  width: 100%;
`;

const openConfig = {
  top: "translate(5, 32) rotate(-45)",
  center: "translate(10, 4) rotate(45)",
  bottom: "translate(5, 32) rotate(-45)",
};

const closeConfig = {
  top: "translate(2, 7) rotate(0)",
  center: "translate(2, 19) rotate(0)",
  bottom: "translate(2, 31) rotate(0)",
};

const MenuIcon = ({ isMenuActive, toggleMenu }) => {
  const { top, center, bottom } = useSpring({
    to: isMenuActive ? openConfig : closeConfig,
    config: config.default,
  });
  return (
    <StyledHamburgerButton isMenuActive={isMenuActive} onClick={toggleMenu}>
      <StyledHamburgerIcon
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="#FFFFFF"
        xmlns="http://www.w3.org/2000/svg"
      >
        <animated.rect width="40" height="6" rx="3" transform={top} />
        <animated.rect width="40" height="6" rx="3" transform={center} />
        <animated.rect width="40" height="6" rx="3" transform={bottom} />
      </StyledHamburgerIcon>
    </StyledHamburgerButton>
  );
};

export default MenuIcon;
