export default {
  darkGrey: "#A2A2A2",
  primaryBlack: "#000000",
  primaryBlue: "#4EAEF8",
  primaryGreen: "#23D160",
  primaryGrey: "#F8F8F8",
  primaryPurple: "#2F1453",
  primaryRed: "#ff0000",
  primaryWhite: "#FFFFFF",
  secondaryGrey: "#CBCBCB",
  secondaryPurple: "#7B58AA",
  secondaryRed: "#FF3860",
};
