import React from "react";
import styled from "styled-components";
import { useTransition, animated } from "react-spring";

import ScreenReaderOnly from "./screen-reader-only";

import colors from "../styles/colors";
import device from "../styles/media-queries";

import menuList from "../data/navigation.json";
import { isWindow } from "../utils";

if (isWindow()) {
  require("smooth-scroll")('a[href*="#"]', {
    speed: 300,
    easing: "easeOutCubic",
    offset: 65,
  });
}

const StyledMainNavigation = styled.nav`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  transition: 0.5s all ease;
  width: 100%;
  visibility: ${props => (props.isMenuActive ? "visible" : "hidden")};
  z-index: ${props => (props.isMenuActive ? 10 : 0)};

  @media ${device.tablet} {
    display: inline-flex;
    flex-direction: row;
    position: relative;
    height: 100%;
    width: auto;
    visibility: visible !important;

    &::after {
      content: none;
    }
  }
`;

const StyledNavigationLink = styled(animated.a)`
  &::before {
    color: ${colors.primaryBlue};
    content: '0${props => props.index + 1}';
    display: block;
    font-weight: 300;
    font-size: 1.4rem;
  }

  color: ${colors.primaryWhite};
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 0.042rem;
  line-height: 1.64;
  padding: 1.25rem 1.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  z-index: 10;

  @media ${device.tablet} {
    text-align: left;
    font-size: 1.3rem;
    opacity: 1 !important;
    transform: translate3d(0, 0, 0) !important;
  }

  @media ${device.laptop} {
    font-size: 1.5rem;
    padding: 1.25rem 2rem;
  }

  @media ${device.laptopL} {
    font-size: 1.7rem;
    padding: 2rem 3.5rem;
  }

`;
const MainNavigation = ({ isMenuActive, toggleMenuActive }) => {
  const transitions = useTransition(menuList, menu => menu.url, {
    initial: {
      opacity: 0,
      transform: `translate3d(0, 100%, 0)`,
    },
    leave: {
      opacity: 0,
      transform: `translate3d(0, 100%, 0)`,
    },
    update: (item, index) => ({
      opacity: isMenuActive ? 1 : 0,
      transform: isMenuActive
        ? `translate3d(0, 100%, 0)`
        : `translate3d(0, 100%, 0)`,
    }),
    trail: isMenuActive ? 500 / menuList.length + 200 : 200,
  });

  function handleClick() {
    toggleMenuActive(false);
  }

  return (
    <StyledMainNavigation isMenuActive={isMenuActive}>
      {transitions.map(({ item, props }, index) => (
        <StyledNavigationLink
          href={item.url}
          key={`main-navigation-${index}`}
          index={index}
          style={props}
          onClick={handleClick}
        >
          {item.title}
        </StyledNavigationLink>
      ))}
      <ScreenReaderOnly as="h2">About me</ScreenReaderOnly>
    </StyledMainNavigation>
  );
};

export default MainNavigation;
