import React, { Fragment, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import colors from "../styles/colors";
import device from "../styles/media-queries";
import { isWindow } from "../utils";

import Hamburger from "./hamburger";

import MainNavigation from "./main-navigation";
import MainHeading from "./main-heading";
import MenuOverlay from "./menu-overlay";

const StyledHeader = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 8rem;
  justify-content: space-between;
  margin: 0 auto;
  padding: 2rem 2.5rem;
  position: fixed;
  transition: 0.5s all ease;
  top: 3.5rem;
  width: 100%;
  z-index: 10;

  @media ${device.tablet} {
    overflow: auto;
    top: 5.5rem;
  }

  @media ${device.laptop} {
    height: auto;
    left: 0;
    justify-content: space-around;
  }

  ${props =>
    props.isOverTop &&
    css`
      background: ${colors.primaryPurple};
      position: fixed;
      transform: translateY(-3.5rem);

      @media ${device.tablet} {
        transform: translateY(-5.5rem);
      }
    `}
`;

const Header = () => {
  const [isOverTop, toggleOverTop] = useState(false);
  const [isMenuActive, toggleMenuActive] = useState(false);
  const toggleTopY = 25;
  const headerRef = useRef();

  const toggleMenu = () => toggleMenuActive(!isMenuActive);

  useEffect(() => {
    if (isWindow() && window.scrollY >= toggleTopY) {
      toggleOverTop(true);
    }
  }, []);

  useScrollPosition(
    ({ currPos }) => {
      const shouldBeVisible = currPos.y >= toggleTopY;

      if (shouldBeVisible && shouldBeVisible !== isOverTop) {
        toggleOverTop(shouldBeVisible);
      } else {
        toggleOverTop(shouldBeVisible);
      }
    },
    [],
    headerRef,
    true,
    150
  );

  return (
    <Fragment>
      <StyledHeader ref={headerRef} isOverTop={isOverTop}>
        <MainHeading />
        <Hamburger isMenuActive={isMenuActive} toggleMenu={toggleMenu} />
        <MainNavigation
          isMenuActive={isMenuActive}
          toggleMenuActive={toggleMenuActive}
        />
      </StyledHeader>
      <MenuOverlay isMenuActive={isMenuActive} />
    </Fragment>
  );
};

export default Header;
