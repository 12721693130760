import React, { Fragment } from "react";
import styled, { css, createGlobalStyle } from "styled-components";
import { useTransition, animated } from "react-spring";

const StyledMenuOverlay = styled(animated.div)`
  background: rgba(0, 0, 0, 0.9);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2;
`;

const GlobalStyle = createGlobalStyle`
  ${props =>
    props.isMenuActive &&
    css`
      html,
      body {
        overflow: hidden;
      }
    `}
`;

const MenuOverlay = ({ isMenuActive }) => {
  const fadeInArray = useTransition(isMenuActive, null, {
    from: {
      opacity: 0,
      transform: `translate3d(100%,0,0)`,
    },
    enter: { opacity: 1, transform: `translate3d(0,0,0)` },
    leave: { opacity: 0, transform: `translate3d(100%,0,0)` },
    trail: 200,
  });

  return (
    <Fragment>
      <GlobalStyle isMenuActive={isMenuActive} />

      {fadeInArray.map(
        ({ item, key, props }) =>
          item && (
            <StyledMenuOverlay
              key={`overlay-${key}`}
              style={props}
            ></StyledMenuOverlay>
          )
      )}
    </Fragment>
  );
};

export default MenuOverlay;
